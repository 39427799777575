@import '../../../assets/styles/pxToRem';

.howToInstall {
    margin-top: rem(10px);
    
    &.closedWindow {
        margin-top: rem(130px);
    }
}

.howToInstallTitle {
    display: flex;
    justify-content: center;
    font-size: rem(23px);
    font-weight: bold;
    color: var(--color-white);
}

.androidContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-size: rem(25px);
}

.androidLogo {
    width: rem(50px);
    margin: rem(20px) 0;
}

.downloadButtonContainer {
    width: 90%;
    height: rem(60px);
    margin: rem(30px) 0 rem(150px) 0;
}