@import '../../../../../assets/styles/pxToRem';

.videeSectionBack {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-black);
    opacity: 0.7;
    z-index: 4;
    margin: auto;
    width: 100%;
    max-width: rem(480px);

    @media screen and (min-width: 1020px) {
      max-width: none;
    }
}


.videoCloseButton {
  position: fixed;
  top: rem(10px);
  right: rem(5px);
  z-index: 5;
  max-width: rem(480px);

  @media screen and (min-width: 1020px) {
    top: rem(15px);
    right: rem(15px);
    cursor: pointer;
  }
}

.videoSection {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    margin: auto;
    width: 90%;
    height: 90%;
    max-width: rem(480px);

    @media screen and (min-width: 1020px) {
      max-width: none;
    }
}