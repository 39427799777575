@import '../../../../assets/styles/pxToRem';

.sloteProviders {
    margin-top: rem(20px);

    @media screen and (min-width: 1019px) {
      display: flex;
      margin-top: rem(60px);
      margin-left: rem(150px);
    }
}

.sloteProvidersContainer {
    touch-action: pan-y;
    margin-left: rem(30px);
}

.top {
    width: rem(25px);
    position: absolute;

    @media screen and (min-width: 1020px) {
      width: rem(30px);

    }
}

.provider0{
    width: rem(150px);
    padding-top: rem(30px);

    @media screen and (min-width: 1020px) {
      width: rem(130px);
    }

    @media screen and (max-width: 1019px) {
      width: rem(250px);
    }

    @media screen and (max-width: 992px){
      width: rem(220px);
    }
    
    @media screen and (max-width: 768px) {
      width: rem(150px);
    }
}

.provider1{
    width: rem(150px);

    @media screen and (min-width: 1020px) {
      width: rem(130px);
    }

    @media screen and (max-width: 1019px) {
      width: rem(250px);
    }

    @media screen and (max-width: 992px){
      width: rem(220px);
    }
    
    @media screen and (max-width: 768px) {
      width: rem(150px);
    }
}

.provider2{
    width: rem(150px);
    padding-top: rem(25px);

    @media screen and (min-width: 1020px) {
      width: rem(130px);
    }

    @media screen and (max-width: 1019px) {
      width: rem(250px);
    }

    @media screen and (max-width: 992px){
       width: rem(220px);
    }
    
    @media screen and (max-width: 768px) {
      width: rem(150px);
    }
}

.provider3{
    width: rem(150px);
    padding-top: rem(13px);

    @media screen and (min-width: 1020px) {
      width: rem(130px);
    }

    @media screen and (max-width: 1019px) {
      width: rem(250px);
    }

    @media screen and (max-width: 992px){
      width: rem(220px);
    }
    
    @media screen and (max-width: 768px) {
      width: rem(150px);
    }
}