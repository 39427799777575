// @import "media";
@import 'pxToRem';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  background-color: #090A0E;
  min-width: rem(360px);

  @media screen and (min-width: 1920px) {
    font-size: 22px;
  }

  @media screen and (max-width: 1920px) {
    font-size: 20px;
  }

  @media screen and (max-width: 1666px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1140px) {
    font-size: 12px;
  }

  @media screen and (max-width: 1019px) {
    font-size: 34px;
  }

  @media screen and (max-width: 992px){
    font-size: 32px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
}

* {
  box-sizing: inherit;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::after, ::before {
    box-sizing: inherit;  
  }
}


body {
  margin: 0 auto;
  min-width: rem(360px);
}

// .container {
//   margin: 0 auto;
//   width: rem(1088px);
// }

// .hidden {
//   display: none;
// }

// .img-container {
//   position: absolute;
//   inset: 0;
//   width: 100%;
//   height: 100%;
// }

// .no-scroll {
//   overflow: hidden;
// }

// .customSlide .slick-slide {
//   margin-right: rem(10px);
// }