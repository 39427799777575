@import '../../../../../assets/styles/pxToRem';


.howToInstallContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 22%;
    margin-left: rem(20px);
    margin-bottom: rem(3px);
}

.APKWindowMargin {
    margin-top: rem(40px);
}

.downloadButtonContainer {
    height: rem(60px);
    margin: rem(15px) 0 0 0;
}