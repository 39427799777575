@import '../../../assets/styles/pxToRem';

.liveSection {
    position: relative;
    height: rem(645px);
    background-image: url('../../../assets/imgs/Background/liveBack.svg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    margin-bottom: rem(-110px);

    @media screen and (min-width: 1020px) {
        display: flex;
        justify-content: center;
        height: rem(850px);
        background-image: url('../../../assets/imgs/Background/desktopLiveBack.svg');
        width: 50%;
    }
}

.basketball{
    position: absolute;
    width: rem(400px);
    height: rem(400px);
    top: rem(140px);
    right: rem(-150px);
    opacity: 0.7;

    @media screen and (min-width: 1020px) {
        width: rem(600px);
        height: rem(600px);
        top: rem(150px);
    }
}

.livePresent {
    padding-top: rem(175px);
    padding-left: rem(25px);

    @media screen and (min-width: 1020px) {
        padding-top: rem(207px);
    }
}

.liveTitle {
    display: flex;
    align-items: center;
    font-size: rem(23px);
    font-weight: bold;
    color: var(--color-lightgreen);

    @media screen and (min-width: 1020px) {
        font-size: rem(30px); 
    }
}

.liveIcon {
    width: rem(50px);
    height: rem(40px);
    margin-right: rem(8px);
}

.liveOption {
    display: flex;
    flex-direction: column;
    margin-top: rem(20px);
    color: var(--color-white);
    font-size: rem(43px);
    font-weight: bold;

    @media screen and (min-width: 1020px) {
        font-size: rem(46px); 
        margin-top: rem(40px);
    }
}

.sports {
    margin-bottom: rem(5px);
}

.countContainer {
    display: flex;
    color: var(--color-white);
    font-size: rem(24px);
    font-weight: bold;
    margin-top: rem(25px);
}

.count {
    display: flex;
    align-items: center;
}

.colorGreen {
    color: var(--color-lightgreen);
}

.greenLine {
    border: rem(0.01px) solid var(--color-lightgreen);
    margin: 0 rem(15px);
}
