@import '../../../../assets/styles/pxToRem';

.HowToInstallTextsContainer {
    @media screen and (min-width: 1020px) {
        width: 100%;
        height: rem(60px);
    }
}

.howtoinstalltext {
    text-align: center;
    color: var(--color-gray2);
    font-size: rem(20px);
    margin: rem(30px);
    font-weight: 300;

    @media screen and (min-width: 1020px) {
        font-size: rem(20px);
        margin: 0 0 rem(20px) 0;
    }
}