@import '../../../assets/styles/pxToRem';

.footerSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: rem(500px);
    background-image: url('../../../assets/imgs/Background/desktopFooterMainBanner.png');
    background-size: auto 150%;
    background-repeat: no-repeat;
    background-position-x: rem(-160px);
    background-position-y: rem(-220px);
    padding-right: rem(50px);
}

.footerContent {
    width: rem(380px);
    margin-bottom: rem(100px);
}

.apkWindowFooter {
    margin-bottom: rem(30px);
}