@import '../../../../assets/styles/pxToRem';

.howToInstallDesktop {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-image: url("../../../../assets/imgs/Background/HowToInstallBaner.png");
    background-size: auto 120%;
    background-position-x: center;
    background-repeat: no-repeat;
}

.howToInstallDesktopTitle {
    display: flex;
    justify-content: center;
    font-size: rem(23px);
    font-weight: bold;
    color: var(--color-white);
    margin-top: rem(40px);
}

.howToInstallDesktopContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}