@import '../../../assets/styles/pxToRem';

.apkWindow {
    display: flex;
    position: fixed;
    top: 0;
    height: fit-content;
    padding: rem(5px);
    background-color: var(--color-gray3);
    z-index: 3;
    max-width: rem(480px);

    &.footer {
        position: relative;
        background-color: unset;
        padding: rem(0px);
    }
}

.apkLogo {
    width: rem(110px);
    height: rem(110px); 

    @media screen and (min-width: 1020px) {
        width: rem(110px);
        height: rem(110px); 
    }
}

.apkDescriptionContainer {
    display: flex;
}

.apkdescription {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: rem(5px);
    padding-top: rem(5px);
}

.apkName {
    font-size: rem(16px);
    color: var(--color-white);
}

.apkdescriptiontext {
    font-size: rem(10px);
    color: var(--color-gray4);

    @media screen and (min-width: 1020px) {
        margin: rem(5px) 0 rem(10px) 0;
    }
    
}

.apkVersion {
    font-size: rem(12px);
    color: var(--color-gray2);
}

.downloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: rem(40px);
    text-decoration: none;
    background-image: linear-gradient(#9AC540, #3C5012);
    border-radius: rem(6px);
    font-size: rem(18px);
    color: var(--color-white);
}

.downloadIcon {
    width: rem(12px);
    margin-right: rem(5px);
}