@import '../../../../assets/styles/pxToRem';

.benefitOptionsDesktop {
    display: flex;
    justify-content: center;
    margin-left: rem(20px);
}

.benefitOptionsPoints {
    width: rem(400px);
}

.optionContainer {
    margin: rem(25px) rem(20px) rem(30px) 0;
}

.option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: rem(25px);
    background-color: var(--color-gray);
    border-radius: rem(8px);
    height: rem(200px);
    color: var(--color-white);
    font-size: rem(30px);
}

.benefitLogo {
    width: rem(60px);
    height: rem(60px);
}

.benefitText {
    margin: rem(20px) 0 rem(5px) 0;
}

.multilingualBack {
    background-image: url('../../../../assets/imgs/Benefits/multilingualBack.svg');
    background-repeat: no-repeat;
    background-size: auto 70%;
}

.fastLoadingBack {
    background-image: url('../../../../assets/imgs/Benefits/fastLoadingBack.svg');
    background-repeat: no-repeat;
    background-position-y: rem(61px);
    background-size: auto 70%;
}

.internetSavingBack {
    background-image: url('../../../../assets/imgs/Benefits/internetSavingBack.svg');
    background-repeat: no-repeat;
    background-position-y: rem(60px);
    background-size: auto 70%;
}

.langs {
    color: var(--color-lightgreen);
    font-size: rem(19px);
}

.pointSectionDesktop {
    display: flex;
    align-items: center;
}

.pointLogoDesktop {
    width: rem(60px);
    margin-right: rem(20px);
}

.pointTextDesktop {
    width: 70%;
    color: var(--color-gray2);
    font-size: rem(20px);
}