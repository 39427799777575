@import '../../../assets/styles/pxToRem';

.mainSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: rem(600px);
    background-image: url('../../../assets/imgs/Background/landingMainBanner.png');
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: rem(-20px);
    z-index: 5;

    @media screen and (min-width: 1020px) {
        height: rem(1095px);
        background-image: url('../../../assets/imgs/Background/desktopLandingMainBanner.png');
        background-position: center;
        margin-top: rem(-200px);
    }
}

.titleContainer {
    padding-top: rem(25px);

    @media screen and (min-width: 1020px) {
        padding-top: rem(300px);
    }
}

.androidHeadContainer {
    display: flex;
    justify-content: center;
}

.mainTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    margin-top: rem(10px);

    @media screen and (min-width: 1020px) {
        flex-direction: row;
        margin-top: 0;
    }
}

.androidHead {
    width: rem(45px);

    @media screen and (min-width: 1020px) {
        width: rem(70px);
        margin-bottom: rem(15px);
    }
}

.firstText {
    font-size: rem(21px);
    font-weight: 400;
    color: var(--color-lightgreen);
}

.secondText {
    font-size: rem(31px);
    font-weight: bold;
    color: var(--color-white);

    @media screen and (min-width: 1020px) {
        margin: 0 rem(15px);
        font-weight: 500;
    }
}

.thirdTextContainer {
    display: flex;
    flex-wrap: nowrap;
    font-size: rem(35px);
    font-weight: 300;
}

.thirdTextContainerSw {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: rem(30px);
    font-weight: 300;

    @media screen and (min-width: 1020px) {
        font-size: rem(31px);
        flex-direction: row;
    }
}

.thirdtextSw {
    @media screen and (min-width: 1020px) {
        font-size: rem(31px);
        margin: 0 rem(10px);
    }
}

.greenText {
    display: flex;
    align-items: flex-end;
    font-size: rem(21px);
    font-weight: 400;
    color: var(--color-lightgreen);
    margin-right: rem(5px);
    margin-bottom: rem(3px);

    @media screen and (min-width: 1020px) {
        align-items: center;  
        margin-right: rem(15px);
        margin-bottom: rem(-2px);
        font-weight: 400;
     }
}

.fixedButtonMargin {
    margin-bottom: rem(60px);
}

.secondTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    font-size: rem(25px);
    font-weight: 300;
}

.firstLine {
    display: flex;
}

.fire {
    width: rem(24px);
    height: rem(24px);
    margin: 0 rem(2px) 0 rem(8px);
}

.hottest {
    color: var(--color-lightgreen);
}
