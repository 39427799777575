@import '../../../assets/styles/pxToRem';

.fixedButtonsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.fixedButtons {
    display: flex;
    position: fixed;
    text-align: center;
    bottom: rem(20px);
    color: var(--color-white);
    height: rem(55px);
    width: 96%;
    max-width: rem(460px);
    z-index: 6;
    
    @media screen and (min-width: 1020px) {
        position: relative;
        width: 100%; 
        bottom: 0;
    }
}

.howtoIButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53%;
    background-image: linear-gradient(#4E5769, #252932 );
    border-radius: rem(29px) 0 0 rem(29px);
    cursor: pointer;
}

.downloadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--color-white);
    width: 47%;
    background-image: linear-gradient(#9AC540, #3C5012 );
    border-radius: 0 rem(29px) rem(29px) 0;
}