@import '../../../../assets/styles/pxToRem';

.promosContainer {
    padding-top: rem(3px);

    @media screen and (min-width: 1020px) {
        display: flex;
        padding: rem(20px) rem(20px) 0 rem(20px);
    }
}

.promosSection {
    width: 100%;

    @media screen and (min-width: 1020px) {
        width: 100%;
        height: auto;
    }
}

.promosImg {
    width: 100%;
    height: 100%;
    padding-left: rem(3px);
}