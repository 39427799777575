@import '../../../../assets/styles/pxToRem';

.howToInstallSlidesContainer {
  position: relative;
  overflow-x: hidden;

  @media screen and (min-width: 1020px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.howToInstallSlides {
  @media screen and (min-width: 1020px) {
    width: 23%;
  }
}

.screenShotsContainer{
    margin-top: rem(10px);
    width: rem(180px);
}

.screens {
    width: rem(180px);
    opacity: 0.6;
    margin: auto;
    padding-top: rem(20px);

    @media screen and (min-width: 1020px) {
      opacity: 1;
      padding-top: 0;
      width: rem(280px);
    }

    @media only screen and (max-width: 480px) {
      width: rem(180px);
    }

    @media only screen and (max-width: 400px) {
      width: rem(140px);
    }

    &.active {
        width: rem(200px);
        padding-top: 0;
        opacity: 1;

        @media screen and (min-width: 1020px) {
          width: rem(280px);
        }

        @media only screen and (max-width: 480px) {
          width: rem(200px);
        }
    
        @media only screen and (max-width: 400px) {
          width: rem(160px);
        }
    }
}

.rightArrowContainer {
  position: absolute;
  top: 40%;
  right: 10%;
  z-index: 2;

  &.changeTop {
    top: 40%;
  }
}

.leftArrowContainer {
    position: absolute;
    top: 40%;
    left: 10%;
    z-index: 2;

    &.changeTop {
      top: 40%;
    }
}

.rightArrowDesktopContainer {
  margin-left: rem(20px);
  display: flex;
  align-items: center;
}

.leftArrowDesktopContainer {
  margin-right: rem(20px);
  display: flex;
  align-items: center;
}