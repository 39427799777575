:root {
    --color-white: rgb(255, 255, 255);
    --color-black: rgb(0, 0, 0);
    --color-lightgreen: rgb(164, 198, 57);
    --color-lightgreen2: rgb(155, 201, 48);
    --color-gray: rgb(37, 41, 50);
    --color-gray2: rgb(191, 191, 191);
    --color-gray3: rgb(29, 32, 39);
    --color-gray4: rgb(165, 165, 165);
    --color-gray5: rgb(91, 97, 123);
    --color-gray6: rgb(37, 41, 50);
    --color-gray7: rgb(63, 72, 93);
}
