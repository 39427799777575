@import '../../../../assets/styles/pxToRem';

.downloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
    background-image: linear-gradient(#9AC540, #3C5012);
    border-radius: rem(29px);
    color: var(--color-white);
}