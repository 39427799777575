@import '../../../assets/styles/pxToRem';

.benefitSection {
    position: relative;
    height: rem(560px);
    background-image: url('../../../assets/imgs/Background/benefitBack.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 4;
    margin-bottom: rem(-110px);

    @media screen and (min-width: 1020px) {
        background-image: url('../../../assets/imgs/Background/desktopBenefitBack.svg');
        background-size: auto 100%;
        height: rem(650px);
    }
}

.affordable {
    display: flex;
    justify-content: center;
    padding-top: rem(20px);
    color: var(--color-white);
    font-size: rem(25px);
    font-weight: 300;
}

.secondTitle {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: rem(20px);
    color: var(--color-white);
    font-size: rem(26px);
    font-weight: 300;
}

.fire {
    width: rem(24px);
    margin-left: rem(10px);
    margin-right: rem(3px);
}

.hottest {
    color: var(--color-lightgreen);
    margin-right: rem(10px);
}
