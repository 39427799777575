@import '../../../../assets/styles/pxToRem';

.benefitPoints {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: rem(20px) 0 0 rem(20px);
    padding-right: rem(20px);
}

.pointSection {
    display: flex;
    align-items: center;
    margin-top: rem(15px);
}

.pointLogo {
    width: rem(35px);
    height: rem(35px);
    margin-right: rem(7px);
}

.pointText {
    font-size: rem(18px);
    color: var(--color-gray2);
}