@import '../../assets/styles/pxToRem';

.landingPage {
    overflow: hidden;
    position: relative;
}

.preLiveSections {
    @media screen and (min-width: 1020px) {
        display: flex; 
        width: 100%;
        margin-bottom: rem(-90px);
    }
}