@import '../../../../../assets/styles/pxToRem';

.numbersConstainer {
    display: flex;
    justify-content: center;
    margin-top: rem(20px);
}

.numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(37px);
    height: rem(37px);
    margin-right: rem(5px);
    color: var(--color-white);
    background-image: url("../../../../../assets/imgs/ellipse.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    font-size: rem(20px);
    font-weight: bold;
    color: var(--color-gray5);
    cursor: pointer;
  
    &.active {
      color: var(--color-lightgreen2);
      background-image: url("../../../../../assets/imgs/ellipseActive.svg");
    }
}