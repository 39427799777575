@import '../../../assets/styles/pxToRem';

.prematchSection {
    position: relative;
    height: rem(600px);
    background-image: url('../../../assets/imgs/Background/prematchBack.svg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    z-index: 3;
    margin-bottom: rem(-150px);

    @media screen and (min-width: 1020px) {
        display: flex;
        justify-content: center;
        height: rem(850px);
        background-image: url('../../../assets/imgs/Background/desktopPrematchBack.svg');
        width: 50%;
        z-index: 1;
        // margin-bottom: rem(-350px);
    }
}

.tennisBall {
    position: absolute;
    width: rem(250px);
    height: rem(255px);
    top: rem(30px);
    right: rem(-45px);
    opacity: 0.8;

    @media screen and (min-width: 1020px) {
        width: rem(300px);
        height: rem(310px);
        top: rem(300px);
    }
}

.football{
    position: absolute;
    width: rem(400px);
    height: rem(400px);
    top: rem(70px);
    left: rem(-190px);
    opacity: 0.7;

    @media screen and (min-width: 1020px) {
        width: rem(600px);
        height: rem(600px);
        top: rem(-160px);
        left: rem(-190px);
    }
}

.prematchPresent {
    padding-top: rem(175px);
    padding-left: rem(25px);

    @media screen and (min-width: 1020px) {
        padding-top: rem(200px);
    }
}

.prematchTitle {
    display: flex;
    align-items: center;
    font-size: rem(23px);
    font-weight: bold;
    color: var(--color-lightgreen);

    @media screen and (min-width: 1020px) {
        font-size: rem(30px); 
    }
}

.prematchIcon {
    width: rem(50px);
    height: rem(45px);
    margin-right: rem(8px);
}

.prematchOption {
    display: flex;
    flex-direction: column;
    margin-top: rem(20px);
    color: var(--color-white);
    font-size: rem(43px);
    font-weight: bold;

    @media screen and (min-width: 1020px) {
        font-size: rem(46px); 
        margin-top: rem(40px);
    }
}

.sports {
    margin-bottom: rem(5px);
}

.countContainer {
    display: flex;
    color: var(--color-white);
    font-size: rem(24px);
    font-weight: bold;
    margin-top: rem(25px);
}

.count {
    display: flex;
    align-items: center;
}

.colorGreen {
    color: var(--color-lightgreen);
}

.greenLine {
    border: rem(0.01px) solid var(--color-lightgreen);
    margin: 0 rem(15px);
}