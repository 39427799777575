@import '../../../../assets/styles/pxToRem';

.benefitOptions {
    margin-top: rem(20px);
}

.optionContainer {
    padding-right: rem(10px);
    margin-bottom: rem(20px);
    width: rem(220px);
}

.option {
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding-top: rem(25px);
    background-color: var(--color-gray);
    border-radius: rem(8px);
    height: rem(130px);
    color: var(--color-white);
    font-size: rem(23px);
}

.benefitLogo {
    width: rem(35px);
    height: rem(35px);
}

.benefitText {
    margin: rem(5px) 0;
}

.multilingualBack {
    background-image: url('../../../../assets/imgs/Benefits/multilingualBack.svg');
    background-repeat: no-repeat;
    background-size: auto 70%;
}

.fastLoadingBack {
    background-image: url('../../../../assets/imgs/Benefits/fastLoadingBack.svg');
    background-repeat: no-repeat;
    background-position-y: rem(38px);
    background-size: auto 70%;
}

.internetSavingBack {
    background-image: url('../../../../assets/imgs/Benefits/internetSavingBack.svg');
    background-repeat: no-repeat;
    background-position-y: rem(40px);
    background-size: auto 70%;
}

.langs {
    color: var(--color-lightgreen);
    font-size: rem(12px);
}