@import '../../../assets/styles/pxToRem';

.casinoSection {
    position: relative;
    height: rem(750px);
    background-image: url('../../../assets/imgs/Background/casinoBack.svg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    margin-bottom: rem(-210px);

    @media screen and (min-width: 1020px) {
        height: rem(1050px);
        background-image: url('../../../assets/imgs/Background/desktopCasinoBack.svg');
    }

    @media screen and (max-width: 1019px) {
        height: rem(960px);
    }
    
    @media screen and (max-width: 992px){
        height: rem(920px);
    }
    
    @media screen and (max-width: 768px) {
        height: rem(850px);
    }
}

.dice {
    position: absolute;
    width: rem(150px);
    height: rem(132px);
    left: rem(60px);
    z-index: -1;

    @media screen and (min-width: 1020px) {
        width: rem(200px);
        height: rem(175px);
        left: rem(850px);
        top: rem(600px);
    }
}

.card {
    position: absolute;
    width: rem(280px);
    height: rem(240px);
    right: rem(20px);
    top: rem(200px);
    z-index: -1;

    @media screen and (min-width: 1020px) {
        width: rem(400px);
        height: rem(345px);
        right: rem(150px);
    }
}

.casinoPresent {
    padding-top: rem(80px);
    padding-left: rem(25px);

    @media screen and (min-width: 1020px) {
        padding-top: rem(130px);
        padding-left: rem(180px);
    }
}

.casinoTitle {
    display: flex;
    align-items: center;
    font-size: rem(23px);
    font-weight: bold;
    color: var(--color-lightgreen);

    @media screen and (min-width: 1020px) {
        font-size: rem(26px);
    }
}

.casinoIcon {
    width: rem(50px);
    height: rem(50px);
    margin-right: rem(8px);
}

.cashBackSection {
    display: flex;
    align-items: flex-end;
    color: var(--color-white);
    font-weight: bold;
    font-size: rem(24px);
    margin-top: rem(30px);
    margin-bottom: rem(8px);

    @media screen and (min-width: 1020px) {
        font-size: rem(34px);
        margin-bottom: rem(30px);
    }
}

.upTotext {
    margin-right: rem(5px);

    @media screen and (min-width: 1020px) {
        margin-right: rem(10px);
    }
}

.persent {
    font-size: rem(65px);
    line-height: 0.85;
    margin-right: rem(6px);

    @media screen and (min-width: 1020px) {
        font-size: rem(89px);
    }
}

.greenText {
    color: var(--color-lightgreen);
}

.casinoSlotes {
    font-size: rem(43px);
    font-weight: bold;
    color: var(--color-white);

    @media screen and (min-width: 1020px) {
        font-size: rem(49px);
    }
}

.casinoSlotesText {
    display: flex;
    flex-wrap: nowrap;
}