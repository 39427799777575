@import '../../../../../assets/styles/pxToRem';

.videoPlayerContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: rem(10px);
    max-width: rem(480px);
    width: 100%;
    z-index: 3;

    @media screen and (min-width: 1020px) {
        margin-top: rem(30px);
        position: static;
    }
}

.videoPlayerButton {
    display: flex;
    width: 90%;
    padding: rem(10px);
    background-color: var(--color-gray6);
    border: 1px solid var(--color-gray7);
    border-radius: rem(24px);
    cursor: pointer;

    @media screen and (min-width: 1020px) {
        width: 100%;
    }
}

.videoPlayerIconContainer {
    width: rem(70px);
    height: rem(70px);

    @media screen and (min-width: 1020px) {
        width: rem(70px);
        height: rem(70px);
    }
}

.videoPlayerIcon {
    width: 100%;
    height: 100%;
}

.videoPlayerText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 rem(5px);
    font-size: rem(18px);
    color: var(--color-white);

    @media screen and (min-width: 1020px) {
        font-size: rem(14px);
        padding: 0 rem(10px);
    }

    @media only screen and (max-width: 400px) {
        font-size: rem(14px);
    }
}

.videoPlayerText2 {
    display: flex;
    flex-wrap: nowrap;
}

.greenText {
    color: var(--color-lightgreen);
    margin-right: rem(5px);
}   
